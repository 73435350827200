import React, { Component } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import ReactTable from "react-table"
import "react-table/react-table.css"
import "../../style/react-table-custom.css"
import EditOrderBooking from "../../components/editOrderBooking/index"
import Tooltip from "../../components/tooltip"
import moment from "moment"
import request from "../../utils/request"
import Pagination from "../../components/paginationTable/pagination"
import Button from "../../components/button"
import TabComponent from "./../../components/tabComponent"
import DatePicker from "react-datepicker"
import Rightbar from "../../layouts/orderManagementLayout/rightbar"
import ExportModal from "../../layouts/orderManagementLayout/exportModal"
import "./bookingList.css"
import { findIndex } from "lodash"
const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.active};
  background: #fff;
  height: max-content;
  width: 100%;
  position: relative;
  top: -1px;
  padding: 10px;
  align-items: flex-start;
  overflow: auto;
`
const TextCollum = styled.div`
  margin-bottom: 0.5rem;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
`
const Overlay = styled.div`
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
`

class OrderManagementBooking extends Component {
  constructor(props) {
    super(props)
    this.state = {
      column: this.props.columnOrderManagementBooking,
      data: [],
      paramsDate: {},
      loading: false,
      numberPage: 1,
      totalPages: 0,
      totaldata: 0,
      page: 0,
      start_datetime: new Date(),
      end_datetime: new Date(),
      textSearchBooking: "",
      checktext: "",
      showRightbar: false,
      isExportModalShow: false,
      tempDataTable: [],
      isMount: false,
      searchOption: "",
      columnWillShow: [],
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.updateSatatusBooking !== undefined || this.props.updateSatatusBooking !== null) {
      if (this.props.updateSatatusBooking !== "") {
        this.props.satatusBooking("")
        this.getDataBooking()
        console.log("start_2")
        this.setState({ totaldata: 0 })
      }
    }

    if (prevState.column !== this.props.columnOrderManagementBooking) {
      this.setState({ column: this.props.columnOrderManagementBooking })
      this.setColumWillShow()
    }
  }

  async componentDidMount() {
    this.props.setOrderMode("Booking")
    this.setColumWillShow()
    await this.setDateForFectData()
    // console.log("start");
  }

  setColumWillShow = () => {
    const allColumns = [
      {
        Header: () => <TextCollum>Internal ID</TextCollum>,
        accessor: "Internal_ID",
        minWidth: 75,
      },
      {
        Header: () => <TextCollum>Payment Date</TextCollum>,
        accessor: "Payment_date",
        minWidth: 130,
        Cell: (row) =>
          row.original.Payment_date ? (
            <Button
              id="test"
              style={{
                border: "1px solid transparent",
                padding: "0px 0px ",
                width: "auto",
                background: "none",
                color: "#3e5569",
                userSelect: "text",
              }}
              label={row.original.Payment_date}
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.Payment_date)
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <TextCollum>Language</TextCollum>,
        accessor: "Language",
        minWidth: 70,
      },
      {
        Header: () => <TextCollum>Copy Info</TextCollum>,
        accessor: "Copy_Info",
        minWidth: 70,
        Cell: (row) =>
          row.original.Copy_Info ? (
            <Button
              id="test"
              style={{
                border: "1px solid transparent",
                padding: "1px 1px ",
                width: "auto",
                fontSize: "12px",
                background: "none",
                color: "white",
                backgroundColor: "#6E8CA0",
                userSelect: "text",
              }}
              label="Copy"
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.Copy_Info)
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <TextCollum>Order ID </TextCollum>,
        accessor: "Order_ID",
        minWidth: 140,
        Cell: (row) =>
          row.original.Order_ID ? (
            <Button
              id="test"
              style={{
                border: "1px solid transparent",
                padding: "0px 0px ",
                width: "auto",
                background: "none",
                color: "#3e5569",
                userSelect: "text",
              }}
              label={row.original.Order_ID}
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.Order_ID)
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <TextCollum>Channel</TextCollum>,
        accessor: "Payment_Method",
        minWidth: 65,
        Cell: (row) =>
          row.original.Payment_Method ? (
            <Button
              id="test"
              style={{
                border: "1px solid transparent",
                padding: "0px 0px ",
                width: "auto",
                background: "none",
                color: "#3e5569",
                userSelect: "text",
              }}
              label={row.original.Payment_Method}
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.Payment_Method)
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <TextCollum>Amount</TextCollum>,
        accessor: "Payment_Amount",
        minWidth: 75,
        Cell: (row) =>
          row.original.Payment_Amount ? (
            <Button
              id="test"
              style={{
                border: "1px solid transparent",
                padding: "0px 0px ",
                width: "auto",
                background: "none",
                color: "#3e5569",
                userSelect: "text",
              }}
              label={row.original.Payment_Amount.toFixed(2)}
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.Payment_Amount.toFixed(2))
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <TextCollum>User ID</TextCollum>,
        accessor: "User_ID",
        minWidth: 60,
        Cell: (row) => (
          <div
            className="maidAllTable"
            style={{
              userSelect: "text",
            }}>
            {row.original !== "noData" ? (
              row.original.User_ID === -1 ? (
                "-"
              ) : (
                <Button
                  id="test"
                  style={{
                    border: "1px solid transparent",
                    padding: "0px 0px ",
                    width: "auto",
                    background: "none",
                    color: "#3e5569",
                    userSelect: "text",
                  }}
                  label={row.original.User_ID}
                  className="copyClipBoard"
                  onClick={(e) => {
                    return navigator.clipboard.writeText(row.original.User_ID)
                  }}></Button>
              )
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        Header: () => <TextCollum>User Name</TextCollum>,
        accessor: "User_Name",
        width: 100,
        Cell: (row) =>
          row.original.User_Name ? (
            <Button
              id="test"
              style={{
                border: "1px solid transparent",
                padding: "0px 0px ",
                width: "auto",
                background: "none",
                color: "#3e5569",
                userSelect: "text",
              }}
              label={row.original.User_Name}
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.User_Name)
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <TextCollum>User Phone</TextCollum>,
        accessor: "User_Phone",
        minWidth: 100,
        Cell: (row) =>
          row.original.User_Phone ? (
            <Button
              id="test"
              style={{
                border: "1px solid transparent",
                padding: "0px 0px ",
                width: "auto",
                background: "none",
                color: "#3e5569",
                userSelect: "text",
              }}
              label={row.original.User_Phone}
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.User_Phone)
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <TextCollum>Order Type</TextCollum>,
        accessor: "Order_Type",
        minWidth: 90,
        Cell: (row) =>
          row.original.Combined_Order_Type ? (
            <Button
              id="test"
              style={{
                border: "1px solid transparent",
                padding: "0px 0px ",
                width: "auto",
                background: "none",
                color: "#3e5569",
                userSelect: "text",
              }}
              label={row.original.Combined_Order_Type}
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.Combined_Order_Type)
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <TextCollum>MP Credits</TextCollum>,
        accessor: "MP_Credits",
        minWidth: 60,
        Cell: (row) =>
          row.original.MP_Credits ? (
            <Button
              id="test"
              style={{
                border: "1px solid transparent",
                padding: "0px 0px ",
                width: "auto",
                background: "none",
                color: "#3e5569",
                userSelect: "text",
              }}
              label={row.original.MP_Credits}
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.MP_Credits)
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <TextCollum>Status</TextCollum>,
        accessor: "Status",
        minWidth: 140,
        Cell: (row) => (
          <div style={{ width: "100%", cursor: "pointer" }}>
            <Tooltip
              placement="bottom"
              trigger="click"
              tooltip={
                <EditOrderBooking
                  TypeStatus={row.original.id}
                  CheckStatus={row.original.Status}
                  BookingId={row.original.booking_ID}
                />
              }>
              {row.original.Status}
              {/* <Icon ><img style={{width:"5px"}} alt="moreIcon" src={MoreIcon} /></Icon> */}
            </Tooltip>
          </div>
        ),
      },
      {
        Header: () => <TextCollum>Time to accept</TextCollum>,
        accessor: "Time_to_accept",
        minWidth: 100,
        Cell: (row) =>
          row.original.Time_to_accept ? (
            <Button
              id="test"
              style={{
                border: "1px solid transparent",
                padding: "0px 0px ",
                width: "auto",
                background: "none",
                color: "#3e5569",
                userSelect: "text",
              }}
              label={row.original.Time_to_accept}
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.Time_to_accept)
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <TextCollum>Maid ID</TextCollum>,
        accessor: "App_ID",
        minWidth: 50,
        Cell: (row) => (
          <div className="maidAllTable">
            {row.original !== "noData" ? (
              row.original.App_ID === -1 ? (
                "-"
              ) : (
                <Button
                  id="test"
                  style={{
                    border: "1px solid transparent",
                    padding: "0px 0px ",
                    width: "auto",
                    background: "none",
                    color: "#3e5569",
                    userSelect: "text",
                  }}
                  label={row.original.App_ID}
                  className="copyClipBoard"
                  onClick={(e) => {
                    return navigator.clipboard.writeText(row.original.App_ID)
                  }}></Button>
              )
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        Header: () => <TextCollum>Maid Name</TextCollum>,
        accessor: "Name_maid",
        minWidth: 100,
        Cell: (row) =>
          row.original.Name_maid ? (
            <Button
              id="test"
              style={{
                border: "1px solid transparent",
                padding: "0px 0px ",
                width: "auto",
                background: "none",
                color: "#3e5569",
                userSelect: "text",
              }}
              label={row.original.Name_maid}
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.Name_maid)
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <TextCollum>Maid_Phone</TextCollum>,
        accessor: "Maid_Phone",
        minWidth: 100,
        Cell: (row) =>
          row.original.Maid_Phone ? (
            <Button
              id="test"
              style={{
                border: "1px solid transparent",
                padding: "0px 0px ",
                width: "auto",
                background: "none",
                color: "#3e5569",
                userSelect: "text",
              }}
              label={row.original.Maid_Phone}
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.Maid_Phone)
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <TextCollum>Booking Date</TextCollum>,
        accessor: "Booking_Date",
        minWidth: 88,
        Cell: (row) =>
          row.original.Booking_Date ? (
            <Button
              id="test"
              style={{
                border: "1px solid transparent",
                padding: "0px 0px ",
                width: "auto",
                background: "none",
                color: "#3e5569",
                userSelect: "text",
              }}
              label={row.original.Booking_Date}
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.Booking_Date)
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <TextCollum>Start Time</TextCollum>,
        accessor: "Start_Time",
        minWidth: 75,
        Cell: (row) =>
          row.original.Start_Time ? (
            <Button
              id="test"
              style={{
                border: "1px solid transparent",
                padding: "0px 0px ",
                width: "auto",
                background: "none",
                color: "#3e5569",
                userSelect: "text",
              }}
              label={row.original.Start_Time}
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.Start_Time)
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <TextCollum>Location</TextCollum>,
        accessor: "Location",
        minWidth: 500,
        Cell: (row) =>
          row.original.Location ? (
            <Button
              id="test"
              style={{
                border: "1px solid transparent",
                padding: "0px 0px ",
                width: "auto",
                background: "none",
                color: "#3e5569",
                userSelect: "text",
              }}
              label={row.original.Location}
              className="copyClipBoard"
              onClick={(e) => {
                return navigator.clipboard.writeText(row.original.Location)
              }}></Button>
          ) : (
            <p>-</p>
          ),
      },
      {
        Header: () => <TextCollum>Additional Detail</TextCollum>,
        accessor: "Additional_Detail",
        minWidth: 200,
      },
      {
        Header: () => <TextCollum>Accomodation</TextCollum>,
        accessor: "Accomodation",
        minWidth: 100,
      },
      {
        Header: () => <TextCollum>Duration</TextCollum>,
        accessor: "Duration",
        minWidth: 60,
      },
      {
        Header: () => <TextCollum>Services</TextCollum>,
        accessor: "Services",
        minWidth: 190,
        Cell: (row) => {
          // console.log("roew", row.original)
          return (
            <div className="ApproveTraining">
              {row.original.Services == "cleaning and drapery"
                ? "CLEANING AND IRONING"
                : row.original.Services == "drapery"
                ? "IRONING"
                : row.original.Services !== "-" && row.original !== "noData"
                ? row.original.Services.toUpperCase()
                : "-"}
            </div>
          )
        },
      },
      {
        Header: () => <TextCollum>Equipment</TextCollum>,
        accessor: "Equipment",
        minWidth: 95,
      },
      {
        Header: () => <TextCollum>Additional Info</TextCollum>,
        accessor: "Additional_Info",
        minWidth: 95,
      },
      {
        Header: () => <TextCollum>Comments</TextCollum>,
        accessor: "Comments",
        minWidth: 850,
        Cell: (row) =>
        row.original.Comments ? (
          <Button
            id="test"
            style={{
              border: "1px solid transparent",
              padding: "0px 0px ",
              width: "auto",
              background: "none",
              color: "#3e5569",
              userSelect: "text",
            }}
            label={row.original.Comments}
            className="copyClipBoard"
            onClick={(e) => {
              return navigator.clipboard.writeText(row.original.Comments)
            }}></Button>
        ) : (
          <p>-</p>
        ),
      },
      {
        Header: () => <TextCollum>Create Date</TextCollum>,
        accessor: "Create_Date",
        minWidth: 130,
      },
    ]

    let cWS = allColumns.filter(obj => {
      return this.state.column.some(colName => colName.isShow && obj.accessor === colName.accessor);
    });

    this.setState({ columnWillShow: cWS })
  }

  async setDateForFectData() {
    /// set default Date ///
    const d = new Date()
    const n = d.getDate()
    let tempD = await JSON.parse(localStorage.getItem("BookingDate"))
    if (tempD) {
      this.setState({
        start_datetime: new Date(tempD.startDate),
        end_datetime: new Date(tempD.endDate),
      })
    } else {
      if (n <= 15) {
        this.setState({
          start_datetime: new Date(moment().set("date", 1).format()),
          end_datetime: new Date(moment().set("date", 15).format()),
        })
      } else if (n >= 16) {
        this.setState({
          start_datetime: new Date(moment().set("date", 16).format()),
          end_datetime: new Date(moment(d).endOf("month").format()),
        })
      }
    }
    await this.getDataBooking()
  }
  async getDataBooking(page, textSearchBooking = "", checktext = true) {
    if (
      this.state.start_datetime !== "" ||
      this.state.end_datetime !== "" ||
      !textSearchBooking
    ) {
      if (
        this.state.start_datetime !== undefined ||
        this.props.end_datetime !== undefined ||
        !textSearchBooking
      ) {
        const params = {
          start_date: this.state.start_datetime
            ? moment(this.state.start_datetime).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          end_date: this.state.end_datetime
            ? moment(this.state.end_datetime).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          textSearchBooking: checktext ? this.state.checktext : textSearchBooking,
          page: page ? page.page : 1,
        }
        this.setState({ loading: true, data: [], tempDataTable: [] })
        if (params) {
          // await this.props.getOrderBookList({
          //   start_date: params.start_date,
          //   end_date: params.end_date,
          //   textSearchBooking: params.textSearchBooking,
          // });
          const checkIsPageBooking2 = window.location.href.includes("/2/Booking2")
          const list = checkIsPageBooking2 ? "list2" : "list"
          let limit = 5000
          await request
            .get(
              `booking/${list}?start_date=${params.start_date}&end_date=${params.end_date}&search=${params.textSearchBooking.trim()}&page=${params.page}&limit=${limit}&by=${this.state.searchOption}`
            )
            .then((resp) => {
              let data = resp.data
              let tempDate = []
              console.log("data", data.booking[0])
              try {
                if (data.booking) {
                  for (let i = 0; i < data.booking.length; i++) {
                    console.log(data.booking[i].payment)
                    tempDate.push({
                      real_user_id: data.booking[i].user.id,
                      booking_ID: data.booking[i].id,
                      Internal_ID: data.booking[i].internal_id ? data.booking[i].internal_id : "-",
                      Order_ID: data.booking[i].external_id ? data.booking[i].external_id : "-",
                      booking_date: data.booking[i].create_datetime
                        ? moment(data.booking[i].create_datetime).format("YYYY-MM-DD")
                        : "-",
                      Payment_date: this.onsetPaymentData(data.booking[i].payment),
                      Payment_Method: this.paymentMethodTranslate(data.booking[i].payment_status),
                      Payment_Amount: data.booking[i].total_price
                        ? parseFloat(data.booking[i].total_price)
                        : 0,
                      booking_Method: "-",
                      User_ID: data.booking[i].user ? data.booking[i].user.customer_id : -1,
                      User_Name: data.booking[i].user
                        ? data.booking[i].user.first_name + " " + data.booking[i].user.last_name
                        : "-",
                      User_Phone: data.booking[i].user ? data.booking[i].user.phone : "-",
                      Order_Type: data.booking[i].type
                        ? data.booking[i].type === "MULTI_PACKAGE"
                          ? "MP"
                          : data.booking[i].type === "ONE_TIME_MANUAL_SELECT"
                          ? "OTM"
                          : "OTA"
                        : "-",
                      MP_Credits: data.booking[i].multi_times ? data.booking[i].multi_times : "-",
                      Status: data.booking[i].booking_status ? data.booking[i].booking_status : "-",
                      Time_to_accept: data.booking[i].time_to_accept
                        ? data.booking[i].time_to_accept
                        : "-",
                      App_ID: data.booking[i].maid ? data.booking[i].maid.real_maid_id : -1,
                      Name_maid: data.booking[i].maid
                        ? ( data.booking[i].maid.user.nick_name ? data.booking[i].maid.user.nick_name : "" ) +
                          " " +
                          ( data.booking[i].maid.user.first_name ? data.booking[i].maid.user.first_name : "" ) +
                          " " +
                          ( data.booking[i].maid.user.last_name ? data.booking[i].maid.user.last_name : "" )
                        : "-",
                      Name_maid_info: data.booking[i].maid
                      ? ( data.booking[i].maid.user.nick_name  ? data.booking[i].maid.user.nick_name : "" ) +
                        " " +
                        ( data.booking[i].maid.user.first_name ? data.booking[i].maid.user.first_name : "" )
                        : "-",
                      Maid_Phone: data.booking[i].maid ? data.booking[i].maid.user.phone : "-",
                      Booking_Date:
                        data.booking[i].booking_calendars.length > 0
                          ? moment(data.booking[i].booking_calendars[0].start_datetime).format(
                              "YYYY-MM-DD"
                            )
                          : "-",
                      Start_Time:
                        data.booking[i].booking_calendars.length > 0
                          ? moment(data.booking[i].booking_calendars[0].start_datetime).format(
                              "HH:mm"
                            )
                          : "-",

                      Location:
                        data.booking[i].location_name && data.booking[i].location_secondary
                          ? data.booking[i].location_name +
                            ", " +
                            data.booking[i].location_secondary
                          : data.booking[i].location_name
                          ? data.booking[i].location_name
                          : data.booking[i].location_secondary
                          ? data.booking[i].location_secondary
                          : "-",
                      Additional_Detail: data.booking[i].location_additional_details || "-",
                      Accomodation: data.booking[i].accommodation
                        ? data.booking[i].accommodation
                        : "-",
                      Duration: data.booking[i].duration ? data.booking[i].duration : "-",
                      Services: this.onSetSetvice(data.booking[i]),
                      Equipment: data.booking[i].bring_equipment ? "1" : "0",
                      Additional_Info:
                        data.booking[i].booking_additional_infos.length > 2
                          ? data.booking[i].booking_additional_infos[0].value +
                            "," +
                            data.booking[i].booking_additional_infos[1].value +
                            "," +
                            data.booking[i].booking_additional_infos[2].value
                          : data.booking[i].booking_additional_infos.length > 1
                          ? data.booking[i].booking_additional_infos[0].value +
                            "," +
                            data.booking[i].booking_additional_infos[1].value
                          : data.booking[i].booking_additional_infos.length > 0
                          ? data.booking[i].booking_additional_infos[0].value
                          : "-",
                      Comments: data.booking[i].description ? data.booking[i].description : "-",
                      Language: data.booking[i].website_language
                        ? data.booking[i].website_language
                        : "-",
                      Create_Date: data.booking[i].create_datetime
                        ? moment(data.booking[i].create_datetime).format("YYYY-MM-DD HH:mm")
                        : "-",
                      // Type_service: !data.booking[i].booking ? null : data.booking[i].booking.type,
                    })
                    const service =
                      tempDate[i].Services == "cleaning and drapery"
                        ? "CLEANING AND IRONING"
                        : tempDate[i].Services == "drapery"
                        ? "IRONING"
                        : tempDate[i].Services !== "-"
                        ? tempDate[i].Services.toUpperCase()
                        : "-"

                    // Combined Order Type with duration+equipment+service column (ex. MP4x4EI, OTM3I),
                    const combinedOrderType = `${
                      tempDate[i].Order_Type === "MP"
                        ? "MP" + tempDate[i].MP_Credits + "x"
                        : tempDate[i].Order_Type
                    }${tempDate[i].Duration}${tempDate[i].Equipment === "1" ? "E" : ""}${
                      service === "CLEANING AND IRONING"
                        ? "I" //"CI"
                        : service === "IRONING"
                        ? "I"
                        : service === "CLEANING"
                        ? "" //"C"
                        : ""
                    }`
                    tempDate[i].Combined_Order_Type = combinedOrderType

                    // tempDate[i].Copy_Info = `Internal ID: ${tempDate[i].Internal_ID}\nPayment Date: ${tempDate[i].Payment_date}\nOrder ID: ${tempDate[i].Order_ID}\nPayment Method: ${tempDate[i].Payment_Method}\nPayment Amount: ${tempDate[i].Payment_Amount}\nUser ID: ${tempDate[i].User_ID}\nUser Name: ${tempDate[i].User_Name}\nUser Phone: ${tempDate[i].User_Phone}\nOrder Type: ${tempDate[i].Combined_Order_Type}\nMP Credits: ${tempDate[i].MP_Credits}\nStatus: ${tempDate[i].Status}\nTime to accept: ${tempDate[i].Time_to_accept}\nMaid ID: ${tempDate[i].App_ID}\nMaid Name: ${tempDate[i].Name_maid}\nMaid Phone: ${tempDate[i].Maid_Phone}\nBooking Date: ${tempDate[i].Booking_Date}\nStart Time: ${tempDate[i].Start_Time}\nLocation: ${tempDate[i].Location}\nAdditional Detail: ${tempDate[i].Additional_Detail}\nAccomodation: ${tempDate[i].Accomodation}\nDuration: ${tempDate[i].Duration}\nServices: ${service}\nEquipment: ${tempDate[i].Equipment}\nAdditional Info: ${tempDate[i].Additional_Info}\nComments: ${tempDate[i].Comments}\nLanguage: ${tempDate[i].Language}\nCreate Date: ${tempDate[i].Create_Date}`
                    tempDate[i].Copy_Info = `${tempDate[i].Order_ID|| '-' } ${tempDate[i].Payment_Method|| '-' } ${tempDate[i].Payment_Amount ? tempDate[i].Payment_Amount.toFixed(2) : '-'} ${tempDate[i].User_ID|| '-' } ${tempDate[i].User_Name|| '-' } ${tempDate[i].User_Phone|| '-' } ${tempDate[i].Combined_Order_Type|| '-' } ${tempDate[i].App_ID|| '-' } ${tempDate[i].Name_maid_info|| '-' } ${tempDate[i].Booking_Date|| '-' } ${tempDate[i].Start_Time || '-'}`
                  }

                  // const sortedTemplate = tempDate.sort(
                  //   (a, b) => moment(b.Create_Date).toDate() - moment(a.Create_Date).toDate()
                  // )
                  // const sortedTemplate = tempDate.sort((a, b) =>
                  //   a.Payment_date < b.Payment_date ? 1 : -1
                  // )
                  // console.log(sortedTemplate)
                  this.setState(
                    {
                      data: tempDate,
                      loading: false,
                      totaldata: data.item_count,
                      totalPages: Math.ceil(data.item_count / limit),
                    },
                    () => this.filterdataList(tempDate)
                  )
                  // this.checkTestStatus(data.booking)
                } else {
                  this.setState({ loading: false, totaldata: 0 , totalPages: 0 })
                }
              } catch (error) {}
            })
        }
      }
    }
  }
  onsetPaymentData(paymentData) {
    if (paymentData) {
      if (paymentData.create_datetime) {
        return moment(paymentData.create_datetime).format("YYYY-MM-DD HH:mm")
      }
    } else {
      return null
    }
  }
  onSetSetvice(data) {
    if (data.booking_services.length > 0) {
      // console.log("data.booking_services", data.id, data.booking_services)
      if (data.booking_services.length > 1) {
        let index = findIndex(data.booking_services, function (o) {
          return o.service === "DRAPERY"
        })

        // console.log(index)
        if (index > 0) {
          return (
            data.booking_services[0].service.toLowerCase() +
            " and " +
            data.booking_services[1].service.toLowerCase()
          )
        } else {
          return (
            data.booking_services[1].service.toLowerCase() +
            " and " +
            data.booking_services[0].service.toLowerCase()
          )
        }
      } else {
        return data.booking_services[0].service.toLowerCase()
      }
    } else {
      return "-"
    }
  }
  // checkTestStatus(data) {
  //   let arr = this.props.statusList
  //   let arr1 = uniq(data.map(function (el) { return el.booking_status }))
  //   let arr2 = arr1.filter(v => v !== null)
  //   for (let i = 0; i < arr2.length; i++) {
  //     if (arr2[i]) {
  //       if (arr.length === 0) {
  //         arr.push({ status: arr2[0], checked: true })
  //       } else {
  //         let index = arr.map(function (el) { return el.status; }).indexOf(`${arr2[i]}`);
  //         if (index === -1 && index < 0) {
  //           arr.push({ status: arr2[i], checked: true })
  //         }
  //       }
  //     }
  //   }
  //   this.props.setStatusListShow(arr)
  // }
  async filterdataList(tempDate) {
    let arr = []
    if (this.props.paymentdateListFillter.length === 1) {
      if (this.props.paymentdateListFillter[0] === "Date") {
        arr = await tempDate.filter((v) => v.Payment_date && this.checkFilterList(v))
        if (arr.length === 0) {
          arr = ["noData"]
        }
      } else {
        arr = await tempDate.filter((v) => !v.Payment_date && this.checkFilterList(v))
        if (arr.length === 0) {
          arr = ["noData"]
        }
      }
    } else if (this.props.paymentdateListFillter.length === 0) {
      arr = ["noData"]
    } else {
      arr = tempDate.filter((v) => this.checkFilterList(v))
      if (arr.length === 0) {
        arr = ["noData"]
      }
    }
    this._on_data_filter(arr)
  }
  checkFilterList(v) {
    return (
      this.props.orderTypeListFillter.includes(v.Order_Type) &&
      this.props.statusListFillter.includes(v.Status) &&
      this.props.mpCreditsFillter.includes(`${v.MP_Credits}`) &&
      this.props.durationFillter.includes(`${v.Duration}`) &&
      this.props.serviceFillter.includes(v.Services.toLowerCase()) &&
      this.props.languageFillter.includes(v.Language)
    )
  }

  handleChangeStartDate = (e) => {
    this.setState({ start_datetime: e }, () => this.getDataBooking())
    let temp = {
      startDate: moment(e).format(),
      endDate: this.state.end_datetime,
    }
    localStorage.setItem("BookingDate", JSON.stringify(temp))
  }
  handleChangeEndDate = (e) => {
    this.setState({ end_datetime: e }, () => this.getDataBooking())
    let temp = {
      startDate: this.state.start_datetime,
      endDate: moment(e).format(),
    }
    localStorage.setItem("BookingDate", JSON.stringify(temp))
  }

  onSenttextSearchBooking = (textSearchBooking = "") => {
      this.getDataBooking({ page: 1 }, textSearchBooking, false)
      this.setState({ checktext: textSearchBooking , page: 0})
  }
  handleToggle = (key) => {
    this.setState((prevState) => ({ [key]: !prevState[key] }))
    const { history } = this.props
    history.push(this.props.history.location.pathname)
  }
  handleColumn = () => {
    this._isMounted = true
    if (this._isMounted) {
      this.setState({ showRightbar: true, mode: "column" })
    }
  }
  handleFilter = () => {
    this._isMounted = true
    if (this._isMounted) {
      this.setState({ showRightbar: true, mode: "filter" })
    }
  }
  onExportData = () => {
    this.setState({ isExportModalShow: true })
  }

  compareBy(key, order) {
    return function (a, b) {
      // a[key] = a[key] === null || a[key] === undefined ? -Infinity : a[key];
      // b[key] = b[key] === null || b[key] === undefined ? -Infinity : b[key];

      // a[key] = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
      // b[key] = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];

      if (order) {
        if (a[key] < b[key]) {
          return 1
        }
        if (a[key] > b[key]) {
          return -1
        }
        return 0
      } else {
        if (a[key] > b[key]) {
          return 1
        }
        if (a[key] < b[key]) {
          return -1
        }
        return 0
      }
    }
  }

  async togleSorting(newSorted, column, shiftKey) {
    let arrayCopy =
      (await this.state.tempDataTable.length) > 0
        ? [...this.state.tempDataTable]
        : [...this.state.data]
    await arrayCopy.sort(this.compareBy(column.id, newSorted[0].desc))
    this.setState({ data: arrayCopy, tempDataTable: arrayCopy })
  }
  _on_data_filter(data) {
    this.setState({ tempDataTable: data })
  }
  openProfileMP(data) {
    const url = `#/admin/order-management/orderProfileBk/${data.real_user_id}/${data.booking_ID}`
    const state = data.real_user_id + "_" + data.booking_ID
    // window.location.href =
    window.open(url, state)
  }
  handleClick = (row) => {
    console.log("row", row)
    // switch (e.detail) {
    //   case 1:
    //     console.log("click")
    //     break
    //   case 2:
    //     console.log("double click")
    //     break
    //   case 3:
    //     console.log("triple click")
    //     break
    // }
  }

  paymentMethodTranslate(method) {
    switch (method) {
      case "ONLINE_BANKING_SUCCESSFUL":
        return "CC/PP"
      case "BANK_TRANSFER_SUCCESSFUL":
        return "BT"
      default:
        return method
    }
  }

  render() {
    const tabData = [
      {
        label: "Booking",
        total: this.state.tempDataTable.length > 0 ? this.state.tempDataTable.length : this.state.totaldata,
        key: "0",
        path: "/Booking",
      },
    ]
    const currentLocation = this.props.history.location.pathname
    return (
      <div>
        {this.state.showRightbar && <Overlay onClick={() => this.handleToggle("showRightbar")} />}
        <div className="col-md-12" style={{ paddingBottom: "10px" }}>
          <div className="row">
            <div className="col-md-6 btn-container" style={{ paddingTop: "25px" }}>
              <Button
                style={{ fontSize: "11px", width: "125px", height: "40px" }}
                className="btn"
                label="Column setting"
                onClick={() => this.handleColumn()}
              />
              <Button
                style={{ fontSize: "11px", width: "125px", height: "40px" }}
                className="btn"
                label="Filter by"
                onClick={() => this.handleFilter()}
              />
              <Button
                style={{
                  display: window.location.href.includes("/2/Booking2") ?  "none" : "block" ,
                  float: "right",
                  fontSize: "11px",
                  width: "125px",
                  height: "40px",
                }}
                className="btn"
                label="Export"
                onClick={() => this.onExportData()}
              />
            </div>
            <div className="col-md-3">
              <div className="col-md-12">
                <label style={{ fontSize: "16px", fontWeight: "500px" }}>Start Date</label>
              </div>
              <div className="col-md-12" style={{ zIndex: 9 }}>
                <DatePicker 
                  className="Rectangle-Datepicker-Arrpove"
                  value={this.state.start_datetime}
                  name="start_datetime"
                  selected={this.state.start_datetime}
                  onChange={this.handleChangeStartDate}
                  timeIntervals={15}
                  dateFormat=" yyyy-MM-dd "
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="col-md-12">
                <label style={{ fontSize: "16px", fontWeight: "500px" }}>End Date</label>
              </div>
              <div className="col-md-12" style={{ zIndex: 9 }}>
                <DatePicker
                  className="Rectangle-Datepicker-Arrpove"
                  value={this.state.end_datetime}
                  name="end_datetime"
                  selected={this.state.end_datetime}
                  onChange={this.handleChangeEndDate}
                  timeIntervals={15}
                  dateFormat=" yyyy-MM-dd "
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
              </div>
            </div>
          </div>
            <div className="col-md-12" style={{ display: "flex", flexDirection: "row" , justifyContent: "end" }}>
              <select
                  className="inPut-search-textAppove-option"
                  id="searchOption"
                  value={this.state.searchOption}
                  onChange={(e) => this.setState({ searchOption: e.target.value } , () =>{
                    let text = document.getElementById("searchText").value
                    if (text !== "") {
                      this.onSenttextSearchBooking(text)
                    }
                  })}
                  >
                  <option value="">All</option>
                  <option value="id">ID</option>
                  <option value="internal_id">Internal ID</option>
                  <option value="external_id">Order ID</option>
                  <option value="user_id">User ID</option>
                  <option value="user_first_name">User First Name</option>
                  <option value="user_last_name">User Last Name</option>
                  <option value="user_phone">User Phone</option>
                  <option value="maid_id">Maid ID</option>
                  <option value="maid_first_name">Maid First Name</option>
                  <option value="maid_last_name">Maid Last Name</option>
                  <option value="maid_phone">Maid Phone</option>
                  <option value="location">Location</option>
                </select>                
                <input
                  id="searchText"
                  type="text"
                  className="inPut-search-textAppove"
                  placeholder="Search.."
                  name="textSearchBooking"
                  // value={this.state.textSearchBooking}
                  // onChange={(e) => this.setState({ textSearchBooking: e.target.value })}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      this.onSenttextSearchBooking(event.target.value)
                    }
                  }}
                />
                <button
                  id="searchTextmaid"
                  type="submit"
                  style={{ marginRight : "0" }}
                  className="search-containe-button"
                  onClick={ () => this.onSenttextSearchBooking(document.getElementById("searchText").value) }>
                  <i className="fa fa-search"></i>
                </button>
            </div>
        </div>
        <div>
          <TabComponent transparent tabData={tabData} onChangeTab={this.onChangeTab} />
          <Card className="col-md-12">
            {this.state.data.length >= 0 ? (
              <ReactTable
                data={
                  this.state.tempDataTable.length > 0 ? this.state.tempDataTable : this.state.data
                } //data object
                columns={this.state.columnWillShow} //column config object
                loading={this.state.loading}
                pageSize={25}
                page={this.state.page}
                showPagination={true}
                PaginationComponent={Pagination}
                pages={this.state.totalPages}
                // resizable={false}
                // defaultSorted={[
                //   {
                //     id: "Internal_ID",
                //     desc: true,
                //   },
                // ]}
                manual
                onSortedChange={(newSorted, column, shiftKey) =>
                  this.togleSorting(newSorted, column, shiftKey)
                }
                onPageChange={(page) =>
                  this.setState({ page: page }, () => {
                    this.getDataBooking({ page: page + 1 })
                  })
                }
                getTrGroupProps={(state, rowInfo) => {
                  if (rowInfo !== undefined) {
                    return {
                      onDoubleClick: () => {
                        if (rowInfo.original.Order_Type === "MP") {
                          this.openProfileMP(rowInfo.original)
                        }
                      },
                    }
                  }
                }}
                getTrProps={(state, rowInfo) => {
                  return {
                    style: {
                      height: 25,
                      overflow: "hidden",
                    },
                  }
                }}
                className="-striped -highlight bookingLsittetable"
              />
            ) : (
              //
              <ReactTable
                data={this.state.data}
                columns={this.state.columnWillShow}
                defaultPageSize={5}
                showPagination={false}
                className=""
              />
            )}
          </Card>
          <Rightbar
            data={this.state}
            currentLocation={currentLocation}
            dataFilter={this.state.data}
            data_filter={(data) => this._on_data_filter(data)}
          />
          <ExportModal
            isOpen={this.state.isExportModalShow}
            onCallBack={this.onCallBack}
            sendForDate={this.state}
            closeModal={(data) => {
              this.setState({ isExportModalShow: data })
            }}
          />
          {this.props.children}
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  customers: state.customer.customers,
  columnOrderManagementBooking: state.orderManagement.columnOrderManagementBooking,
  orderMode: state.orderManagement.orderMode,
  textSearchBooking: state.orderManagement.textSearchBooking,
  totalpageBookinglist: state.orderManagement.totalpageBookinglist,
  paymentdateListFillter: state.orderManagement.paymentdateListFillter,
  orderTypeListFillter: state.orderManagement.orderTypeListFillter,
  statusListFillter: state.orderManagement.statusListFillter,
  mpCreditsFillter: state.orderManagement.mpCreditsFillter,
  durationFillter: state.orderManagement.durationFillter,
  serviceFillter: state.orderManagement.serviceFillter,
  languageFillter: state.orderManagement.languageFillter,
  statusList: state.orderManagement.statusList,
  updateSatatusBooking: state.orderManagement.updateSatatusBooking,
})

const mapDispatch = (dispatch) => {
  return {
    setPageTitle: dispatch.Title.setPageTitle,
    setOrderMode: dispatch.orderManagement.setOrderMode,
    getOrderBookList: dispatch.orderManagement.getOrderBookList,
    setStatusListShow: dispatch.orderManagement.setStatusListShow,
    satatusBooking: dispatch.orderManagement.satatusBooking,
  }
}

export default connect(mapState, mapDispatch)(OrderManagementBooking)
